/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */

@font-face {
    font-family: "Clan Web Pro Book";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Book.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro News";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-News.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro News Italic";
    src: url("https://files.bancobari.com.br/fonts/ Clan-Web-Pro-News-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Bold";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Bold Italic";
    src: url("https://files.bancobari.com.br/fonts/ Clan-Web-Pro-Bold-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Italic";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Medium";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Medium Italic";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Medium-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Narrow News";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Narrow-News.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Narrow New Italic";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Narrow-New-Italic.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Narrow Medium";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Narrow-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Clan Web Pro Thin Italic";
    src: url("https://files.bancobari.com.br/fonts/Clan-Web-Pro-Thin-Italic.woff2") format("woff2");
}
