/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */

@keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(0) translate(0px, 0);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(0) translate(0px, 0px);
    }
}
