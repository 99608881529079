/**
 * @author Matheus Vieira <matheus.vieira@deal.com.br>
 * @since 2020-11-10
 */

@import url('./fonts.css');
@import url('./animations.css');

* {
  margin: 0;
  padding: 0;
}

div,
input,
select,
button {
  outline: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Clan Web Pro Narrow News', sans-serif;
  color: #646464;
}

p {
  letter-spacing: 0.01em;
  font-weight: 500;
}

input,
select {
  display: block;
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  font-family: 'Clan Web Pro News', sans-serif;
  font-size: 14px;
  line-height: 24px;
  color: #8e8e8e;
}

@media only screen and (max-width: 767px) {
  input,
  select {
    padding: 16px;
  }
}

@media only screen and (min-width: 768px) {
  input,
  select {
    padding: 20px;
  }
}

input::-webkit-input-placeholder {
  color: #8e8e8e;
}
